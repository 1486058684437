import {
  type ClientLoaderFunctionArgs,
  Navigate,
  useLoaderData,
  useSearchParams,
} from '@remix-run/react';
import { $path } from 'remix-routes';

import { OnboardingPageLayout } from '../components/Onboarding/OnboardingLayout';
import { type RegisterFor } from '../components/Onboarding/types';
import { OnboardingUtils } from '../components/Onboarding/utils';
import { OrgAdminRequired } from '../components/Organization';
import { useMyOrganization } from '../components/Organization/hooks/organization';
import { PaymentUtils } from '../components/Payment/utils';
import { SubscriptionPayment } from '../components/Product/SubscriptionPayment';
import { ProductUtils } from '../components/Product/utils';
import { apiService } from '../services/api-service';
import { booleanify } from '../utils/common';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const url = new URL(action.request.url);
  const productId = url.searchParams.get('product-id');
  const priceId = url.searchParams.get('price-id');
  const headcount = PaymentUtils.ParseHeadcount(
    url.searchParams.get('headcount')
  );
  const registerFor = OnboardingUtils.ParseRegisterFor(
    url.searchParams.get('register-for')
  );
  const trial = booleanify(url.searchParams.get('trial'));
  const redirectTo = url.searchParams.get('redirect-to');
  const packId = url.searchParams.get('pack-id');

  const resp = await apiService.product.getPublicProducts();
  const product =
    resp.data.published.find((p) => p.id === productId) ||
    resp.data.published.find((p) => p.id === resp.data.defaultProductId);
  const price = product?.prices?.find((p) => p.id === priceId);

  if (!product || !price) {
    return {
      data: undefined,
    };
  }

  return {
    data: {
      product,
      price,
      registerFor,
      redirectTo,
      packId,
      headcount: headcount || price.maxSeats,
      trial,
    },
  };
}

function getRedirectTo(
  registerFor: RegisterFor | null,
  redirectTo: string | null
) {
  if (redirectTo) return redirectTo;
  if (registerFor === 'coldOutreach' || registerFor === 'oneTimePurchase') {
    return '/home';
  }
  return $path('/onboarding/menu', window.location.search);
}

function OnboardingSubscription() {
  const { data } = useLoaderData<typeof clientLoader>();
  const organization = useMyOrganization();
  const [, setSearchParams] = useSearchParams();

  if (!data || data.price.archived) {
    const msg = !data
      ? 'We could not find the product or price you are looking for.'
      : 'This price has expired.';
    return (
      <OnboardingPageLayout progress={30}>
        <div className='text-white text-center'>
          {msg}
          <br />
          Please{' '}
          <a
            href='mailto:support@lunapark.com'
            className='text-primary underline'
          >
            reach out
          </a>{' '}
          for assistance.
        </div>
      </OnboardingPageLayout>
    );
  }

  const { product, price, headcount, trial } = data;
  const redirectTo = getRedirectTo(data.registerFor, data.redirectTo);
  if (
    !organization ||
    price.amount === 0 ||
    organization.subscription.priceId === price.id
  ) {
    return <Navigate to={redirectTo} replace={true} />;
  }

  const handleHeadcountChange = (headcount: number) => {
    const newPrice =
      ProductUtils.FindCheapestPrice(product, {
        headcount,
        billingInterval: price.billingInterval,
      }) || price;

    setSearchParams(
      (prev) => {
        const searchParams = new URLSearchParams(prev);
        searchParams.set('headcount', headcount.toString());
        searchParams.set('price-id', newPrice.id);
        return searchParams.toString();
      },
      {
        replace: true,
      }
    );
  };

  return (
    <OnboardingPageLayout progress={30}>
      <SubscriptionPayment
        organization={organization}
        product={product}
        price={price}
        headcount={headcount}
        trial={trial}
        returnUrl={redirectTo}
        onHeadcountChange={handleHeadcountChange}
      />
    </OnboardingPageLayout>
  );
}

export function Component() {
  return (
    <OrgAdminRequired
      denied={<Navigate to={$path('/subscription/change')} replace />}
    >
      <OnboardingSubscription />
    </OrgAdminRequired>
  );
}
